// src/App.js

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import AboutUs from "./components/AboutUs";
import Contact from "./components/Contact";
import HomePage from "./components/HomePage";
import Locations from "./components/Locations";
import React from "react";
import Rent from "./components/Rent";

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/about" element={<AboutUs />} />
				<Route path="/locations" element={<Locations />} />
				<Route path="/rental" element={<Rent />} />
				<Route path="/rental/:categoryName" element={<Rent />} />
				<Route path="/contact" element={<Contact />} />
			</Routes>
		</Router>
	);
}

export default App;
