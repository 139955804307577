// src/components/HomePage.jsx

import {
	FaChartLine,
	FaEnvelope,
	FaFilm,
	FaHandsHelping,
	FaMapMarkerAlt,
	FaNetworkWired,
	FaPhoneAlt,
} from "react-icons/fa";

import { NavLink } from "react-router-dom";
import Navbar from "./Navbar";
import React from "react";
import { motion } from "framer-motion";

const services = [
	{
		icon: <FaFilm className="text-red-500 text-3xl" />,
		title: "Complete Production Services",
		description:
			"We provide full-service production support, guiding your project through every stage—from pre-production planning to on-set execution and post-production. Your creative vision is our priority.",
	},
	{
		icon: <FaNetworkWired className="text-red-500 text-3xl" />,
		title: "Extensive Network",
		description:
			"We have a huge network in Balkan’s and Europe, so we are able to offer the best creative crews, such as screen writers, director , cinematographers, editors, 3D artists and technicians, ensuring the best team for your project.",
	},
	{
		icon: <FaChartLine className="text-red-500 text-3xl" />,
		title: "Innovative Approach",
		description:
			"We embrace the latest technologies and creative trends to deliver modern, impactful productions that stand out in a competitive market.",
	},
	{
		icon: <FaHandsHelping className="text-red-500 text-3xl" />,
		title: "Exceptional Crew Support",
		description:
			"Our skilled crew handles every detail of your production with precision, from logistics to technical operations, ensuring a smooth and successful process.",
	},
];

const HomePage = () => {
	return (
		<div className="bg-white text-black">
			{/* Navbar */}
			<Navbar />

			{/* Spacer for fixed header */}
			<div className="h-24"></div>

			{/* Hero Section */}
			<section className="bg-[#394953] text-white py-20">
				<div className="container mx-auto flex flex-col-reverse md:flex-row items-center justify-between px-6">
					{/* Left Content */}
					<motion.div
						className="md:w-1/2"
						initial={{ opacity: 0, x: -50 }}
						animate={{ opacity: 1, x: 0 }}
						transition={{ duration: 1 }}
					>
						<h1 className="text-3xl font-bold mb-6">
							DERA FILM OFFERS PRODUCTION SERVICES AND CREW, IN KOSOVA AND
							ALBANIA
						</h1>
						<div className="flex flex-col sm:flex-row sm:space-x-8">
							{/* Location Box */}
							<motion.div
								className="flex items-start bg-gray-800 bg-opacity-80 p-6 rounded-lg shadow-lg mb-6 sm:mb-0"
								initial={{ opacity: 0, y: 20 }}
								whileInView={{ opacity: 1, y: 0 }}
								viewport={{ once: true }}
								transition={{ duration: 1, delay: 0.2 }}
							>
								<FaMapMarkerAlt className="text-red-500 text-2xl mr-4 mt-1" />
								<div>
									<h3 className="text-xl font-semibold text-red-500">
										LOCATION
									</h3>
									<p>St. Hanry Dynan, Ulpiana C15</p>
									<p>Prishtina 10000, Republic of Kosova</p>
								</div>
							</motion.div>
							{/* Contact Box */}
							<motion.div
								className="flex items-start bg-gray-800 bg-opacity-80 p-6 rounded-lg shadow-lg"
								initial={{ opacity: 0, y: 20 }}
								whileInView={{ opacity: 1, y: 0 }}
								viewport={{ once: true }}
								transition={{ duration: 1, delay: 0.4 }}
							>
								<div className="flex flex-col">
									<FaEnvelope className="text-red-500 text-2xl mr-4 mt-1 mb-2" />
									<FaPhoneAlt className="text-red-500 text-2xl mr-4 mt-1" />
								</div>
								<div>
									<h3 className="text-xl font-semibold text-red-500">
										CONTACT
									</h3>
									<p>
										Email:{" "}
										<a
											href="mailto:latifhasolli@gmail.com"
											className="hover:underline"
										>
											latifhasolli@gmail.com
										</a>
									</p>
									<p>
										Mob/WhatsApp/Viber:{" "}
										<a href="tel:+38344227470" className="hover:underline">
											+38344227470
										</a>
									</p>
								</div>
							</motion.div>
						</div>
					</motion.div>
					{/* Right Image */}
					<motion.div
						className="md:w-1/2 mb-8 md:mb-0"
						initial={{ opacity: 0, x: 50 }}
						animate={{ opacity: 1, x: 0 }}
						transition={{ duration: 1 }}
					>
						<img
							src="/photo1.png" // Replace with your actual image URL
							alt="Dera Film Production"
							className="w-11/12 mx-auto rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
						/>
					</motion.div>
				</div>
			</section>

			{/* What We Do Section */}
			<section className="py-20 bg-white">
				<div className="container mx-auto px-6">
					<h2 className="text-3xl font-bold text-center mb-12 text-red-500">
						What We Do?
					</h2>
					<div className="flex flex-col md:flex-row items-center justify-between">
						{/* Image */}
						<motion.div
							className="md:w-1/2 mb-8 md:mb-0"
							initial={{ opacity: 0, y: 50 }}
							whileInView={{ opacity: 1, y: 0 }}
							viewport={{ once: true }}
							transition={{ duration: 1 }}
						>
							<img
								src="/photo2.png" // Replace with your actual image URL
								alt="What We Do"
								className="w-11/12 mx-auto rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
							/>
						</motion.div>
						{/* Text */}
						<motion.div
							className="md:w-1/2"
							initial={{ opacity: 0, y: -50 }}
							whileInView={{ opacity: 1, y: 0 }}
							viewport={{ once: true }}
							transition={{ duration: 1 }}
						>
							<div className="space-y-6">
								{services.map((service, index) => (
									<motion.div
										key={index}
										className="flex items-start"
										initial={{ opacity: 0, x: -20 }}
										whileInView={{ opacity: 1, x: 0 }}
										viewport={{ once: true }}
										transition={{ duration: 0.5, delay: index * 0.2 }}
									>
										<div className="mr-4">{service.icon}</div>
										<div>
											<h3 className="text-xl font-semibold text-red-500">
												{service.title}
											</h3>
											<p className="text-lg">{service.description}</p>
										</div>
									</motion.div>
								))}
							</div>
						</motion.div>
					</div>
				</div>
			</section>

			{/* Markets We Serve Section */}
			<section className="py-20 bg-[#394953] text-white">
				<div className="container mx-auto px-6">
					<h2 className="text-3xl font-bold text-center mb-6 text-red-500">
						Markets We Serve
					</h2>
					<p className="text-center mb-12">
						We make it possible for film productions to keep up with the latest
						technology and creative trends by providing the resources you need
						to create the right content.
					</p>
					<div className="flex flex-col md:flex-row items-center justify-between">
						{/* Left Image */}
						<motion.div
							className="md:w-1/2 mb-8 md:mb-0"
							initial={{ opacity: 0, x: -50 }}
							whileInView={{ opacity: 1, x: 0 }}
							viewport={{ once: true }}
							transition={{ duration: 1 }}
						>
							<img
								src="/photo3.png" // Replace with your actual image URL
								alt="Markets We Serve"
								className="w-11/12 mx-auto rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
							/>
						</motion.div>
						{/* Right List */}
						<motion.div
							className="md:w-1/2 bg-[#394953] bg-opacity-80 p-6 rounded-lg shadow-lg"
							initial={{ opacity: 0, y: 50 }}
							whileInView={{ opacity: 1, y: 0 }}
							viewport={{ once: true }}
							transition={{ duration: 1 }}
						>
							<ul className="space-y-4">
								{[
									"⁠TV AND FILM SERVICE",
									"⁠PRODUCTION ADVERTISING",
									"⁠AGENCIES CORPORATE & MEDIA",
									"⁠EDUCATION & TRAININGS",
									"⁠EQUIPMENT AND FACILITIES",
									"⁠LOCATIONS SCOUTING",
									"⁠ROAD AND OFF-ROAD TRANSPORTATION",
								].map((item, index) => (
									<li key={index} className="flex items-center">
										<span className="text-red-500 mr-2">•</span> {item}
									</li>
								))}
							</ul>
						</motion.div>
					</div>
				</div>
			</section>

			{/* Footer */}
			<footer className="w-full bg-black text-white py-6">
				<div className="container mx-auto text-center px-6">
					<ul className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-6 mb-4">
						<li>
							<NavLink
								to="/"
								exact
								className="hover:text-red-500 transition-colors"
							>
								Home
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/about"
								className="hover:text-red-500 transition-colors"
							>
								About Us
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/locations"
								className="hover:text-red-500 transition-colors"
							>
								Locations
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/rental"
								className="hover:text-red-500 transition-colors"
							>
								Rental
							</NavLink>
						</li>
						<li>
							<NavLink
								to="/contact"
								className="hover:text-red-500 transition-colors"
							>
								Contact
							</NavLink>
						</li>
					</ul>
					<p>© 2024 Dera Film. All rights reserved.</p>
				</div>
			</footer>
		</div>
	);
};

export default HomePage;
